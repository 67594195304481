import { useStaticQuery, graphql, Link, navigate } from 'gatsby';
import * as React from 'react';
import * as styles from '../../styles/Header.module.scss';
import Logo from '../../images/tekode-logo.svg';

const Header = () => {
  const queryResults = useStaticQuery(graphql`
  query Header {
    prismicHeader {
      data {
        logo_image {
          url
        }
        contact {
          contact_cta {
            text
          }
          contact_title {
            text
          }
        }
        services {
          service {
            document {
              ... on PrismicService {
                id
                uid
                data {
                  service_title {
                    text
                  }
                  service_url {
                    text
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  
  `);

  const scrollToEl = (el) => {
    if(typeof window !== 'undefined' && window.location.pathname === '/'){
      document.getElementById(el).scrollIntoView({behavior: "smooth"});
    } else {
      navigate("/");
    }
  }

  let services = null;
  if (queryResults.prismicHeader.data.services) {
    services = queryResults.prismicHeader.data.services.map((service, index) => {
      return (
        <li key={index} onClick={() => scrollToEl(service.service.document.uid)}><div className={styles.HeaderLink} role="button">{service.service.document.data.service_title.text}</div></li>
      )
    });
  }

  return (
    <header className={styles.HeaderWrap} id="headerRef">
      <nav>
        <Link to={"/"} className={styles.HeaderLogo}><img src={Logo} alt="Tekode Logo" /></Link>
        <ul>
          {services}
        </ul>
        <Link to={queryResults.prismicHeader.data.contact[0].contact_cta.text}>{queryResults.prismicHeader.data.contact[0].contact_title.text}</Link>
      </nav>
    </header>
  )
}

export default Header;