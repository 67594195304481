import * as React from 'react'

import * as styles from '../../styles/Footer.module.scss';

export const Footer = () => (
  <footer className={styles.FooterWrap}>
    <div className={styles.Copyright}>©2022 tekode llc</div>
    <a href="mailto:contact@tekode.com">contact@tekode.com</a>
  </footer>
)
