import * as React from 'react'
import { Footer } from './Footer/Footer';
import Header from './Header/Header'

import * as styles from '../styles/Layout.module.scss';

export const Layout = ({children, navigation }) => (
  <div className={styles.LayoutContainer}>
    <Header />
    <main>{children}</main>
    <Footer />
  </div>
);
